import React, { useMemo } from 'react';
import { useParams } from 'next/navigation';
import { CurrencyHelpers } from 'helpers/currencyHelpers';
import { useFormat } from 'helpers/hooks/useFormat';
import { getShippingMethodImage } from 'helpers/utils/getShippingMethodImage';
import { useCart } from 'frontastic';
import Image from 'frontastic/lib/image';
import Preview from '../wrapper';

interface Props {
  pickupPoint: string | undefined;
}

const ShippingPreview: React.FC<Props> = ({ pickupPoint }) => {
  const { locale } = useParams();

  const { data } = useCart();
  const { formatMessage: formatCheckoutMessage } = useFormat({ name: 'checkout' });

  const shippingMethod = useMemo(() => {
    if (!data?.shippingInfo) return;

    return {
      name: data.shippingInfo.name ?? '',
      price: data.shippingInfo.price,
      key: data.shippingInfo.key,
    };
  }, [data]);

  const isFreeShipping = shippingMethod?.price?.centAmount == 0;

  const shippingAddress = useMemo(() => {
    if (!data?.shippingAddress) return;

    return {
      additionalAddressInfo: data.shippingAddress.additionalAddressInfo,
      additionalStreetInfo: data.shippingAddress.additionalStreetInfo,
      externalId: data.shippingAddress.externalId,
      city: data.shippingAddress.city,
      postalCode: data.shippingAddress.postalCode,
    };
  }, [data]);

  const { src, iconClassName } = getShippingMethodImage(shippingMethod?.key);

  return (
    <Preview>
      <div className="border-neutral-2 lg:border-t">
        <div className="flex items-center justify-between lg:pt-24">
          <div>
            <div className="flex items-center gap-12">
              <Image src={src} alt="shipping method logo" className={iconClassName} />
              <p className="text-16 font-bold text-neutral-4">
                {pickupPoint && shippingAddress?.additionalStreetInfo
                  ? shippingAddress.additionalStreetInfo
                  : shippingMethod?.name}
              </p>
            </div>

            {pickupPoint ? (
              <div className="pt-12">
                <p className="text-16 leading-[20px] text-neutral-4">{shippingAddress?.additionalAddressInfo}</p>
                <p className="pt-4 text-16 leading-[20px] text-neutral-4">
                  {shippingAddress?.postalCode} {shippingAddress?.city} {shippingAddress?.externalId}
                </p>
              </div>
            ) : null}
          </div>

          <span className="text-16 leading-[20px] text-neutral-4">
            {isFreeShipping
              ? formatCheckoutMessage({ id: 'freeShipping', defaultMessage: 'Free Shipping' })
              : CurrencyHelpers.formatForCurrency(shippingMethod?.price ?? {}, locale)}
          </span>
        </div>
      </div>
    </Preview>
  );
};

export default ShippingPreview;
