import Typography from 'components/commercetools-ui/atoms/typography';
import AddressModal from 'components/commercetools-ui/organisms/checkout/components/create-address-modal';
import InfoIcon from 'components/icons/InfoIcon';
import { useFormat } from 'helpers/hooks/useFormat';

export default function InvalidAddressesCheckoutBox() {
  const { formatMessage } = useFormat({ name: 'checkout' });
  const noAddress = formatMessage({
    id: 'invalid.addresses.no.address.selected',
    defaultMessage: 'No delivery address has been set',
  });
  const addAddressToContinue = formatMessage({
    id: 'invalid.addresses.add.address.to.complete.order',
    defaultMessage: 'Please add a new address to proceed with your order',
  });

  return (
    <div className="border border-base-accent-1 p-20">
      <div className="mb-20 flex justify-between gap-20 align-top">
        <div>
          <Typography className="font-bold">{noAddress}</Typography>
          <Typography>{addAddressToContinue}</Typography>
        </div>

        <div className="flex h-40 w-40 shrink-0 items-center justify-center rounded-full border text-base-accent-1">
          <InfoIcon />
        </div>
      </div>

      <AddressModal variant="text-button" />
    </div>
  );
}
