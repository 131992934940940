import React, { useEffect, useRef } from 'react';
import CheckmarkIcon from 'components/icons/CheckmarkIcon';
import PencilIcon from 'components/icons/PencilIcon';
import useClassNames from 'helpers/hooks/useClassNames';

interface Props {
  number: number;
  label: string;
  isExpanded: boolean;
  isCompleted: boolean;
  onEdit: () => void;
  Component: JSX.Element;
  Preview: JSX.Element;
  CTA?: JSX.Element;
}

const Step: React.FC<Props> = ({ number, label, isExpanded, isCompleted, onEdit, Component, Preview, CTA }) => {
  const ref = useRef<HTMLDivElement>(null);

  const numberClassName = useClassNames([
    'rounded-full w-40 h-40 flex items-center justify-center border-2 font-bold',
    {
      'border-base-accent-1 bg-base-accent-1 text-neutral-5': isExpanded,
      'border-base-accent-1 bg-neutral-5 text-base-accent-1': !isExpanded,
    },
  ]);

  useEffect(() => {
    if (isExpanded && ref.current) ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }, [isExpanded]);

  return (
    <div className="shadow-custom flex flex-col gap-24 bg-white px-12 py-24 lg:px-32" ref={ref}>
      <div className="flex items-center justify-between bg-white">
        <div className="flex cursor-default items-center gap-12 lg:gap-16">
          {!isCompleted && <span className={numberClassName}>{number}</span>}

          {isCompleted && !isExpanded && (
            <span className="flex h-40 w-40 items-center justify-center rounded-full border-2 border-base-accent-1 bg-white text-base-accent-1">
              <CheckmarkIcon />
            </span>
          )}

          <p className="text-16 font-bold leading-[20px] text-neutral-4">{label}</p>
        </div>

        {isCompleted && !isExpanded && (
          <span
            className="text-14 text-secondary-black underline decoration-secondary-black underline-offset-2 hover:cursor-pointer"
            onClick={onEdit}
          >
            <PencilIcon />
          </span>
        )}

        {isExpanded && CTA}
      </div>

      {isCompleted && !isExpanded && Preview}

      {isExpanded && Component}
    </div>
  );
};

export default Step;
