import { useCallback } from 'react';
import { useRouter } from 'next/navigation';
import { LineItem } from 'shared/types/cart';
import triggerToast from 'components/commercetools-ui/atoms/toaster/utils/triggerToast';
import { PURCHASE } from 'helpers/constants/events';
import { useFormat } from 'helpers/hooks/useFormat';
import usePagePathWithLocale from 'helpers/hooks/usePagePathWithLocale';
import { lineItemToGoogleAnalytics4Item } from 'helpers/utils/mapGoogleAnalytics';
import { useCart } from 'frontastic';
import { useCheckout } from '../provider';

const usePurchase = () => {
  const { formatMessage: formatCheckoutMessage } = useFormat({ name: 'checkout' });

  const router = useRouter();
  const {
    transaction,
    data,
    hasOutOfStockItems,
    hasMoreItemsThanInStock,
    orderCartPrzelewy24,
    orderCartCashOnDelivery,
  } = useCart();
  const { setProcessing, paymentData } = useCheckout();
  const { thankYouPagePath } = usePagePathWithLocale();

  const trackPurchase = useCallback(
    async (lineItems: Array<LineItem>, transaction_id: string) => {
      const googleAnalytics4Items = lineItems.map((lineItem) => ({
        ...lineItemToGoogleAnalytics4Item(lineItem),
        quantity: lineItem?.count ?? 0,
      }));

      const currency = 'PLN';
      const value = Number(
        googleAnalytics4Items
          .reduce((accumulator, currentValue) => accumulator + currentValue.quantity * currentValue.price, 0)
          .toFixed(2),
      );
      const netValue = Number(
        googleAnalytics4Items
          .reduce((accumulator, currentValue) => accumulator + currentValue.quantity * currentValue.price_net, 0)
          .toFixed(2),
      );
      const enhancedConversions = {
        email: data?.email,
        phone_number: data?.shippingAddress?.phone,
        address: {
          first_name: data?.shippingAddress?.firstName,
          last_name: data?.shippingAddress?.lastName,
          street: data?.shippingAddress?.streetNumber
            ? `${data?.shippingAddress?.streetName} ${data?.shippingAddress?.streetNumber}`
            : data?.shippingAddress?.streetName,
          city: data?.shippingAddress?.city,
          postal_code: data?.shippingAddress?.postalCode,
          country: data?.shippingAddress?.country,
        },
      };

      gtag('event', PURCHASE, {
        currency,
        value,
        value_net: netValue,
        transaction_id,
        user_data: enhancedConversions,
        items: googleAnalytics4Items,
      });
    },
    [data?.email, data?.shippingAddress],
  );

  const purchase = useCallback(async () => {
    if (!data?.shippingAddress || !data?.billingAddress || !data?.shippingInfo) return;

    if (hasOutOfStockItems) {
      const outOfStockItems = data?.lineItems?.filter((lineItem) => lineItem.variant?.isOnStock) ?? [];

      const messageOutOfStock = formatCheckoutMessage({
        id: 'items.outOfStock',
        defaultMessage: 'The following items are out of stock',
      });
      const itemsOutOfStock = outOfStockItems.map(
        (item, i) => `- ${item.name}${i === outOfStockItems.length - 1 ? '' : '\n\n'}`,
      );

      triggerToast({ variant: 'error', message: `${messageOutOfStock}:\n\n${itemsOutOfStock}` });

      return;
    }

    if (hasMoreItemsThanInStock) {
      const moreItemsThanInStock =
        data?.lineItems?.filter((lineItem) => {
          if (lineItem.count && lineItem.variant?.availableQuantity) {
            return lineItem.count > lineItem.variant?.availableQuantity;
          }
        }) ?? [];
      const maximumLocalised = formatCheckoutMessage({
        id: 'items.maximum',
        defaultMessage: 'maximum',
      });

      const messageLimitedStock = formatCheckoutMessage({
        id: 'items.limitedStock',
        defaultMessage: 'The following items have exceeded available quantity',
      });

      const itemsLimitedStock = moreItemsThanInStock.map(
        (item, i) =>
          `- ${item.name}: ${maximumLocalised} ${item.variant?.availableQuantity}${
            i === moreItemsThanInStock.length - 1 ? '' : '\n\n'
          }`,
      );

      triggerToast({ variant: 'error', message: `${messageLimitedStock}:\n\n${itemsLimitedStock}` });

      return;
    }

    if (!transaction.total.centAmount) {
      triggerToast({
        variant: 'error',
        message: formatCheckoutMessage({
          id: 'payment.failed',
          defaultMessage: 'We could not process your payment, please try again later.',
        }),
      });

      return;
    }

    setProcessing(true);

    if (paymentData.type === 'cash_on_delivery') {
      const response = await orderCartCashOnDelivery();

      if (response.orderId) {
        trackPurchase(response.lineItems ?? [], response.orderId);
        router.push(`${thankYouPagePath}?orderId=${response.orderId}`);
      } else {
        triggerToast({
          variant: 'error',
          message: formatCheckoutMessage({
            id: 'payment.failed',
            defaultMessage: 'We could not process your payment, please try again later.',
          }),
        });
      }
    } else {
      const response = await orderCartPrzelewy24();

      if (response.p24Url) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        trackPurchase(response.lineItems ?? [], response.orderId!);
        router.push(response.p24Url);
      } else {
        triggerToast({
          variant: 'error',
          message: formatCheckoutMessage({
            id: 'payment.failed',
            defaultMessage: 'We could not process your payment, please try again later.',
          }),
        });
      }
    }

    setProcessing(false);
  }, [
    data?.shippingAddress,
    data?.billingAddress,
    data?.shippingInfo,
    data?.lineItems,
    hasOutOfStockItems,
    hasMoreItemsThanInStock,
    transaction.total.centAmount,
    setProcessing,
    paymentData.type,
    formatCheckoutMessage,
    orderCartCashOnDelivery,
    trackPurchase,
    router,
    thankYouPagePath,
    orderCartPrzelewy24,
  ]);

  return { purchase };
};

export default usePurchase;
