import React, { useState } from 'react';
import { useRouter } from 'next/navigation';
import Button from 'components/commercetools-ui/atoms/button';
import Container from 'components/commercetools-ui/atoms/container';
import Drawer from 'components/commercetools-ui/atoms/drawer';
import HeaderLogo from 'components/commercetools-ui/organisms/header/header-logo';
import { EmptyStateProps } from 'components/commercetools-ui/organisms/header/types';
import Slideout from 'components/commercetools-ui/organisms/header/utility-section/components/slide-out';
import CartIcon from 'components/icons/CartIcon';
import HomeIcon from 'components/icons/HomeIcon';
import usePagePathWithLocale from 'helpers/hooks/usePagePathWithLocale';
import { useCart } from 'frontastic';

export type Props = EmptyStateProps;

const Header: React.FC<Props> = ({ ...emptyState }) => {
  const router = useRouter();

  const { totalItems: totalCartItems } = useCart();
  const { homePagePath } = usePagePathWithLocale();

  const [isCartSlideoutOpen, setIsCartSlideoutOpen] = useState(false);

  return (
    <header className="relative h-fit w-full border-b-[1.5px] border-neutral-400 bg-white">
      <Container>
        <div className="flex w-full items-center justify-between px-16 md:px-24 lg:px-20 xl:px-46">
          <div className="ml-0 flex w-full justify-start gap-12 py-12 xl:gap-24 xl:pb-20 xl:pt-24">
            <Button
              className="self-center"
              variant="home"
              size="home"
              onClick={() => router.push(homePagePath)}
              icon={<HomeIcon />}
            ></Button>
            <HeaderLogo imageClassName="items-center justify-between xl:[&>svg]:h-32 xl:[&>svg]:w-180 flex [&>svg]:h-28 w-161" />
          </div>
          <div
            onClick={() => setIsCartSlideoutOpen(true)}
            className="relative right-8 flex h-32 w-fit cursor-pointer items-center"
          >
            <CartIcon />
            {totalCartItems > 0 && (
              <span className="absolute right-[-8px] top-0  h-8 w-8 rounded-full bg-base-brand-1" />
            )}
          </div>

          <Drawer
            isOpen={isCartSlideoutOpen}
            direction="right"
            className="w-full max-w-[435px] bg-white"
            onClose={() => setIsCartSlideoutOpen(false)}
          >
            <Slideout
              state="cart"
              onClose={() => setIsCartSlideoutOpen(false)}
              enableWishlistState={false}
              {...emptyState}
            />
          </Drawer>
        </div>
      </Container>
    </header>
  );
};

export default Header;
