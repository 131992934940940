import React, { useCallback, useMemo, useState } from 'react';
import * as yup from 'yup';
import Button from 'components/commercetools-ui/atoms/button';
import triggerToast from 'components/commercetools-ui/atoms/toaster/utils/triggerToast';
import { useFormat } from 'helpers/hooks/useFormat';
import useProcessing from 'helpers/hooks/useProcessing';
import useYupValidation from 'helpers/hooks/useYupValidation';
import { useAccount, useCart } from 'frontastic';
import { CartDetails } from 'frontastic/hooks/useCart/types';
import AddressForm from '../components/address-form';
import { Fields } from '../components/address-form/types';
import useMappers from '../hooks/useMappers';
import { Address } from '../types';

export interface Props {
  goToNextStep: () => void;
}

const DPDAddress: React.FC<Props> = ({ goToNextStep }) => {
  const { formatMessage } = useFormat({ name: 'common' });
  const { formatMessage: formatErrorMessage } = useFormat({ name: 'error' });
  const { formatMessage: formatCheckoutMessage } = useFormat({ name: 'checkout' });
  const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });

  const { account, loggedIn, shippingAddresses, billingAddresses } = useAccount();
  const { updateCart, data } = useCart();
  const { processing, startProcessing, stopProcessing } = useProcessing();
  const { addressToAccountAddress, accountAddressToAddress } = useMappers();

  const initialData = {
    externalId: '',
    additionalAddressInfo: '',
    additionalStreetInfo: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    line1: '',
    number: '',
    postalCode: '',
    city: '',
    company: '',
  } as Address;

  const defaultShippingAddress = shippingAddresses.find(
    (address) => !address.isDefaultBillingAddress && address.isDefaultShippingAddress,
  );
  const [shippingAddress, setShippingAddress] = useState<Address>(
    defaultShippingAddress ? accountAddressToAddress(defaultShippingAddress) : initialData,
  );
  const billingAddress = billingAddresses.find((address) => address.isDefaultBillingAddress);
  const currentBillingAddress = useMemo(
    () => (billingAddress ? accountAddressToAddress(billingAddress) : shippingAddress),
    [accountAddressToAddress, billingAddress, shippingAddress],
  );

  const { formValidation, fieldValidation } = useYupValidation();

  const requiredError = formatErrorMessage({ id: 'form.required', defaultMessage: 'The field is required' });
  const phoneNumberError = formatErrorMessage({
    id: 'form.phoneNumber',
    defaultMessage: 'Phone number is not valid',
  });

  const schema = useMemo(() => {
    return yup.object().shape({
      firstName: yup.string().trim().required(requiredError),
      lastName: yup.string().trim().required(requiredError),
      phone: yup
        .string()
        .matches(/^\d{9}$/, { message: phoneNumberError, excludeEmptyString: true })
        .required(requiredError),
    });
  }, [requiredError, phoneNumberError]);

  const validateFirstName = useCallback(
    (value: string) => {
      return fieldValidation(schema, shippingAddress, 'firstName', value);
    },
    [fieldValidation, schema, shippingAddress],
  );

  const validateLastName = useCallback(
    (value: string) => {
      return fieldValidation(schema, shippingAddress, 'lastName', value);
    },
    [fieldValidation, schema, shippingAddress],
  );

  const validatePhone = useCallback(
    (value: string) => {
      return fieldValidation(schema, shippingAddress, 'phone', value);
    },
    [fieldValidation, schema, shippingAddress],
  );

  const [isValidForm, setIsValidForm] = useState(formValidation(schema, shippingAddress));

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      const newShippingAddress = { ...shippingAddress, [e.target.name]: e.target.value };
      setShippingAddress(newShippingAddress);
      const isValid = formValidation(schema, newShippingAddress);
      setIsValidForm(isValid);
    },
    [formValidation, schema, shippingAddress],
  );

  const submit = useCallback(async () => {
    if (!isValidForm || processing) return;

    startProcessing();

    const shippingAddressDPD = {
      ...shippingAddress,
      externalId: data?.shippingAddress?.externalId,
      additionalAddressInfo: data?.shippingAddress?.additionalAddressInfo,
      additionalStreetInfo: data?.shippingAddress?.additionalStreetInfo,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-non-null-asserted-optional-chain
      city: data?.shippingAddress?.city!,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-non-null-asserted-optional-chain
      postalCode: data?.shippingAddress?.postalCode!,
    };

    const cartDetails = {
      account: { email: account?.email || shippingAddress.email || currentBillingAddress.email },
      shipping: addressToAccountAddress(shippingAddressDPD),
      billing: addressToAccountAddress(currentBillingAddress),
    } as CartDetails;

    const res = await updateCart(cartDetails);

    stopProcessing();

    if (res.cartId) {
      goToNextStep();
    } else {
      triggerToast({
        variant: 'error',
        message: formatCheckoutMessage({
          id: 'update.addresses.error',
          defaultMessage: "Couldn't update your addresses information, please try again later.",
        }),
      });
    }
  }, [
    isValidForm,
    processing,
    startProcessing,
    shippingAddress,
    data,
    account,
    currentBillingAddress,
    addressToAccountAddress,
    updateCart,
    stopProcessing,
    goToNextStep,
    formatCheckoutMessage,
  ]);

  const fields = useCallback(() => {
    return [
      {
        name: 'firstName',
        label: formatMessage({ id: 'firstName', defaultMessage: 'First Name' }),
        labelDesc: '',
        required: true,
        type: 'string',
        className: 'col-span-4 lg:col-span-2',
        validationSchema: validateFirstName,
      },
      {
        name: 'lastName',
        label: formatMessage({ id: 'lastName', defaultMessage: 'Last Name' }),
        labelDesc: '',
        required: true,
        type: 'string',
        className: 'col-span-4 lg:col-span-2',
        validationSchema: validateLastName,
      },
      {
        name: 'phone',
        label: formatMessage({ id: 'phone', defaultMessage: 'Phone' }),
        labelDesc: '',
        required: true,
        className: 'col-span-4',
        placeholder: '999999999',
        tooltip: formatMessage({
          id: 'phone.info.shipment',
          defaultMessage: 'The phone number will help deliver your shipment more efficiently.',
        }),
        validationSchema: validatePhone,
      },
    ] as Fields[];
  }, [formatMessage, validateFirstName, validateLastName, validatePhone]);

  return (
    <div className="bg-white">
      {loggedIn
        ? shippingAddress && (
            <div className="flex flex-col gap-20">
              <AddressForm address={shippingAddress} fields={fields} onChange={handleChange}></AddressForm>
              <Button
                variant="primary"
                className="w-full min-w-[200px] md:text-16 lg:w-fit lg:px-36"
                disabled={!isValidForm}
                loading={processing}
                type="submit"
                onClick={submit}
              >
                {formatCartMessage({ id: 'continue.to', defaultMessage: 'Continue to' })}
                <span className="lowercase">{formatCartMessage({ id: 'payment', defaultMessage: 'Payment' })}</span>
              </Button>
            </div>
          )
        : null}
    </div>
  );
};

export default DPDAddress;
