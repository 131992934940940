import React, { useCallback, useEffect, useState } from 'react';
import { PaymentMethod, PaymentMethodType } from 'shared/types/cart/Payment';
import Button from 'components/commercetools-ui/atoms/button';
import { PaymentMethodAdditionalText } from 'components/commercetools-ui/organisms/checkout';
import { useCheckout } from 'components/commercetools-ui/organisms/checkout/provider';
import { PaymentData } from 'components/commercetools-ui/organisms/checkout/provider/payment/types';
import { COD_MAX_CART_VALUE } from 'helpers/constants/limitMaxOrderValue';
import { useFormat } from 'helpers/hooks/useFormat';
import useProcessing from 'helpers/hooks/useProcessing';
import { useCart } from 'frontastic';

interface Props {
  goToNextStep: () => void;
  pickupPoint: string | undefined;
  paymentMethodsAdditionalText?: PaymentMethodAdditionalText[];
}

const Payment: React.FC<Props> = ({ goToNextStep, pickupPoint, paymentMethodsAdditionalText }) => {
  const { formatMessage: formatCheckoutMessage } = useFormat({ name: 'checkout' });

  const { data, setPaymentMethod, maxCoDCartValue } = useCart();

  const { getPaymentMethods, setPaymentData } = useCheckout();

  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);

  const [selectedType, setSelectedType] = useState<PaymentMethodType>(
    // We need to change PaymentMethod type to match BE typing (COD|P24). Decided todo after MVP.
    () => (data?.paymentMethod === 'COD' ? 'cash_on_delivery' : 'przelewy24'),
  );

  const isExceedingCoDMaxCartValue = maxCoDCartValue > COD_MAX_CART_VALUE;

  const fetchPaymentMethods = useCallback(async () => {
    const paymentMethods = getPaymentMethods();

    setPaymentMethods(paymentMethods);
  }, [getPaymentMethods]);

  useEffect(() => {
    fetchPaymentMethods();
    setPaymentData({ type: selectedType } as unknown as PaymentData);
  }, [fetchPaymentMethods, setPaymentData, selectedType]);

  const { processing, startProcessing, stopProcessing } = useProcessing();

  const submit = useCallback(async () => {
    if (processing) return;

    startProcessing();

    // We need to change PaymentMethod type to match BE typing (COD|P24). Decided todo after MVP.
    const mappedPaymentMethod = selectedType === 'przelewy24' ? 'P24' : 'COD';
    await setPaymentMethod(mappedPaymentMethod);

    stopProcessing();
    goToNextStep();
  }, [goToNextStep, selectedType, setPaymentMethod, processing, startProcessing, stopProcessing]);

  const handlePaymentMethodSelection = useCallback(
    (type: PaymentMethodType) => {
      if (type !== selectedType) {
        setSelectedType(type);
        setPaymentData({ type } as PaymentData);
      }
    },
    [selectedType, setPaymentData],
  );

  const availablePaymentMethods = pickupPoint
    ? paymentMethods.filter((paymentMethod) => paymentMethod.type !== 'cash_on_delivery')
    : paymentMethods;

  const AdditionalText = ({ type }: { type: PaymentMethodType }) => {
    const additionalText = paymentMethodsAdditionalText?.find((text) => text.key === type);

    return additionalText ? (
      <p className="pt-2 text-14 leading-[17.5px] text-neutral-3">{additionalText.text}</p>
    ) : null;
  };

  return (
    <div className="flex flex-col gap-20">
      <div className="flex flex-col gap-12 bg-white">
        {availablePaymentMethods.map(({ name, type, image }) => (
          <div
            key={type}
            className={`${
              type === selectedType ? 'border-base-accent-1' : 'border-neutral-2'
            } hover:shadow-custom flex cursor-pointer items-center justify-between gap-8 border px-12 py-16`}
            onClick={() => handlePaymentMethodSelection(type)}
          >
            <div className="flex items-center">
              {type === 'cash_on_delivery' && isExceedingCoDMaxCartValue ? (
                <div className="flex flex-col gap-4">
                  <p className="text-16 leading-[20px] text-neutral-3">{name}</p>
                  <p className="text-14 leading-[17.5px] text-status-warning-border">
                    {formatCheckoutMessage({
                      id: 'payment.method.cod.max.cart.value',
                      defaultMessage: 'Max cart value exceeded',
                    })}
                  </p>
                </div>
              ) : (
                <div>
                  <p className="text-16 leading-[20px] text-neutral-4">{name}</p>
                  <AdditionalText type={type} />
                </div>
              )}
            </div>

            {/* eslint-disable-next-line */}
            <img
              src={image.src}
              className={`h-[32px] ${
                isExceedingCoDMaxCartValue && type === 'cash_on_delivery' && 'opacity-40 grayscale'
              }`}
            />
          </div>
        ))}
      </div>

      <Button
        variant="primary"
        className="w-full min-w-[200px] md:text-16 lg:w-fit lg:px-36"
        type="submit"
        onClick={submit}
        disabled={isExceedingCoDMaxCartValue && selectedType === 'cash_on_delivery'}
      >
        {formatCheckoutMessage({ id: 'review.order', defaultMessage: 'Review order' })}
      </Button>
    </div>
  );
};

export default Payment;
