import React, { useCallback, useMemo, useState } from 'react';
import * as yup from 'yup';
import Button from 'components/commercetools-ui/atoms/button';
import Checkbox from 'components/commercetools-ui/atoms/checkbox';
import Modal from 'components/commercetools-ui/atoms/modal';
import Typography from 'components/commercetools-ui/atoms/typography';
import CheckmarkIcon from 'components/icons/CheckmarkIcon';
import CloseIcon from 'components/icons/CloseIcon';
import PencilIcon from 'components/icons/PencilIcon';
import PlusIcon from 'components/icons/PlusIcon';
import { useFormat } from 'helpers/hooks/useFormat';
import useProcessing from 'helpers/hooks/useProcessing';
import useYupValidation from 'helpers/hooks/useYupValidation';
import { useAccount } from 'frontastic';
import AddressForm from '../steps/sections/addresses/components/address-form';
import { Fields } from '../steps/sections/addresses/components/address-form/types';
import useMappers from '../steps/sections/addresses/hooks/useMappers';
import { Address } from '../steps/sections/addresses/types';

export type AddressModalProps = {
  addressToUpdate?: Address;
  variant?: 'edit-icon' | 'add-icon' | 'text-button';
};

const AddressModal = ({ addressToUpdate, variant }: AddressModalProps) => {
  const { formatMessage } = useFormat({ name: 'common' });
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });
  const { formatMessage: formatCheckoutMessage } = useFormat({ name: 'checkout' });
  const { formatMessage: formatErrorMessage } = useFormat({ name: 'error' });
  const buttonLabel = formatCheckoutMessage({
    id: 'invalid.addresses.button.add.address',
    defaultMessage: 'Add delivery address',
  });

  const { processing, startProcessing, stopProcessing } = useProcessing();

  const { addressToAccountAddress } = useMappers();

  const { addShippingAddress, loggedIn, updateAddress, shippingAddresses } = useAccount();

  const [isOpen, setIsOpen] = useState(false);

  const initialData = useMemo(() => addressToUpdate || ({ addressType: 'shipping' } as Address), [addressToUpdate]);

  const [data, setData] = useState<Address>(initialData);

  const openModal = useCallback(() => {
    setData(initialData);
    setIsOpen(true);
  }, [initialData]);

  const closeModal = useCallback(() => setIsOpen(false), []);

  const [saveAsDefault, setSaveAsDefault] = useState(false);

  const [isValidForm, setIsValidForm] = useState(false);

  const requiredError = formatErrorMessage({ id: 'form.required', defaultMessage: 'The field is required' });
  const postalCodeError = formatErrorMessage({
    id: 'form.postalCode',
    defaultMessage: 'The postal code must be 5 digits long',
  });
  const phoneNumberError = formatErrorMessage({
    id: 'form.phoneNumber',
    defaultMessage: 'Phone number is not valid',
  });

  const schema = useMemo(() => {
    return yup.object().shape({
      firstName: yup.string().trim().required(requiredError),
      lastName: yup.string().trim().required(requiredError),
      addressType: yup.string().trim().required(requiredError),
      line1: yup.string().trim().required(requiredError),
      postalCode: yup
        .string()
        .required(requiredError)
        .matches(/^\d{2}(-)?\d{3}?$/, postalCodeError),
      city: yup.string().trim().required(requiredError),
      phone: yup
        .string()
        .matches(/^\d{9}$/, { message: phoneNumberError, excludeEmptyString: true })
        .required(requiredError),
    });
  }, [requiredError, postalCodeError, phoneNumberError]);

  const { formValidation, fieldValidation } = useYupValidation();

  const validateFirstName = useCallback(
    (value: string) => {
      return fieldValidation(schema, data, 'firstName', value);
    },
    [fieldValidation, schema, data],
  );

  const validateLastName = useCallback(
    (value: string) => {
      return fieldValidation(schema, data, 'lastName', value);
    },
    [fieldValidation, schema, data],
  );

  const validateLine1 = useCallback(
    (value: string) => {
      return fieldValidation(schema, data, 'line1', value);
    },
    [fieldValidation, schema, data],
  );

  const validatePostalCode = useCallback(
    (value: string) => {
      return fieldValidation(schema, data, 'postalCode', value);
    },
    [fieldValidation, schema, data],
  );

  const validateCity = useCallback(
    (value: string) => {
      return fieldValidation(schema, data, 'city', value);
    },
    [fieldValidation, schema, data],
  );

  const validatePhone = useCallback(
    (value: string) => {
      return fieldValidation(schema, data, 'phone', value);
    },
    [fieldValidation, schema, data],
  );

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      const newData = { ...data, [e.target.name]: e.target.value };
      setData(newData);
      const isValid = formValidation(schema, newData);
      setIsValidForm(isValid);
    },
    [formValidation, schema, data],
  );

  const handleSubmit = useCallback(async () => {
    startProcessing();
    try {
      if (addressToUpdate?.addressId) {
        await updateAddress({
          ...addressToAccountAddress(data),
          addressId: addressToUpdate.addressId,
          isDefaultShippingAddress: saveAsDefault,
        });
      } else {
        await addShippingAddress({
          ...addressToAccountAddress(data),
          isDefaultShippingAddress: saveAsDefault,
        });
      }
    } finally {
      stopProcessing();
      closeModal();
      setIsValidForm(false);
      setSaveAsDefault(false);
    }
  }, [
    addShippingAddress,
    data,
    addressToAccountAddress,
    saveAsDefault,
    startProcessing,
    stopProcessing,
    closeModal,
    updateAddress,
    addressToUpdate,
  ]);

  const fields = useCallback(() => {
    return [
      {
        name: 'firstName',
        label: formatMessage({ id: 'firstName', defaultMessage: 'First Name' }),
        labelDesc: '',
        required: true,
        type: 'string',
        className: 'col-span-4 lg:col-span-2',
        validationSchema: validateFirstName,
      },
      {
        name: 'lastName',
        label: formatMessage({ id: 'lastName', defaultMessage: 'Last Name' }),
        labelDesc: '',
        required: true,
        type: 'string',
        className: 'col-span-4 lg:col-span-2',
        validationSchema: validateLastName,
      },
      {
        name: 'company',
        label: formatMessage({ id: 'companyName', defaultMessage: 'Company name' }),
        labelDesc: '',
        required: false,
        type: 'string',
        className: 'col-span-4',
      },
      {
        name: 'line1',
        label: formatMessage({ id: 'address', defaultMessage: 'Address' }),
        labelDesc: '',
        required: true,
        type: 'string',
        className: 'col-span-4 lg:col-span-3',
        validationSchema: validateLine1,
      },
      {
        name: 'number',
        label: formatMessage({ id: 'street.number', defaultMessage: 'Number' }),
        labelDesc: '',
        required: false,
        type: 'string',
        className: 'col-span-4 lg:col-span-1',
      },
      {
        name: 'postalCode',
        label: formatMessage({ id: 'zipCode', defaultMessage: 'Postcode' }),
        labelDesc: '',
        required: true,
        className: 'col-span-4 lg:col-span-2 mt-12',
        validationSchema: validatePostalCode,
      },
      {
        name: 'city',
        label: formatMessage({ id: 'city', defaultMessage: 'City' }),
        labelDesc: '',
        required: true,
        className: 'col-span-4 lg:col-span-2 mt-12',
        validationSchema: validateCity,
      },
      {
        name: 'phone',
        label: formatMessage({ id: 'phone', defaultMessage: 'Phone' }),
        labelDesc: '',
        required: true,
        className: 'col-span-4',
        placeholder: '999999999',
        tooltip: formatMessage({
          id: 'phone.info.shipment',
          defaultMessage: 'The phone number will help deliver your shipment more efficiently.',
        }),
        validationSchema: validatePhone,
      },
    ] as Fields[];
  }, [
    formatMessage,
    validateLine1,
    validatePostalCode,
    validateCity,
    validatePhone,
    validateFirstName,
    validateLastName,
  ]);

  // Use similar logic from AccountAddresses to get valid addresses
  const addresses = useMemo(() => {
    return shippingAddresses.filter(
      (address) =>
        (address.isBillingAddress && address.phone && address.streetName && address.postalCode && address.city) ||
        !address.isBillingAddress,
    );
  }, [shippingAddresses]);

  if (!loggedIn) return <></>;

  // Hide button at the top of the "Step" box in Checkout
  if (addresses.length === 0 && variant !== 'text-button') return <></>;

  const Trigger = () => {
    // When used in "invalidAddressesCheckoutBox" in Checkout page
    if (variant === 'text-button') {
      return (
        <Button variant="primary" icon={<PlusIcon />} iconPosition="left" className="max-lg:w-full" onClick={openModal}>
          {buttonLabel}
        </Button>
      );
      // When used in Checkout page step 2 with a valid billing address
    } else if (!addressToUpdate?.addressId) {
      return (
        <Button
          variant="secondaryIconOnly"
          size="icon"
          className="border-neutral-4 text-neutral-4"
          onClick={openModal}
          icon={<PlusIcon scale={0.92} />}
        />
      );
      // Other cases, eg. when inside an address card, to edit current address
    } else {
      return <Button variant="underlined" className="!p-0" icon={<PencilIcon />} onClick={openModal} />;
    }
  };

  return (
    <>
      <Trigger />

      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        style={{ content: { background: 'transparent', border: 'none', maxHeight: '95vh', overflowY: 'auto' } }}
        closeTimeoutMS={200}
      >
        <div className="shadow-custom relative mx-auto bg-white px-12 py-32 lg:w-full lg:px-32">
          <div className="absolute right-12 top-12 hover:cursor-pointer lg:right-24 lg:top-20" onClick={closeModal}>
            <CloseIcon />
          </div>

          <p className="text-center text-28 font-bold leading-[35px] text-neutral-4">
            {formatAccountMessage({ id: 'address.add', defaultMessage: 'Add new address' })}
          </p>

          <AddressForm className="mt-24" address={data} fields={fields} onChange={handleChange} onSubmit={handleSubmit}>
            <div className="mt-24">
              {data.isDefaultShippingAddress ? (
                <div className="flex items-center gap-4">
                  <div className="h-24 w-24">
                    <CheckmarkIcon scale={1} />
                  </div>
                  <Typography className="text-16 leading-[20px] text-neutral-4">
                    {formatAccountMessage({ id: 'default', defaultMessage: 'Default delivery address' })}
                  </Typography>
                </div>
              ) : (
                <Checkbox
                  label={formatAccountMessage({
                    id: 'address.setDefault',
                    defaultMessage: 'Save as default address',
                  })}
                  labelPosition="on-right"
                  checked={saveAsDefault}
                  onChange={({ checked }) => {
                    setSaveAsDefault(checked);
                    setIsValidForm(formValidation(schema, data));
                  }}
                />
              )}
            </div>

            <div className="mt-32 flex gap-12">
              <Button
                variant="primary"
                className="basis-1/2 px-48"
                type="submit"
                loading={processing}
                disabled={!isValidForm}
              >
                {formatMessage({ id: 'save', defaultMessage: 'Save' })}
              </Button>

              <Button variant="secondary" className="basis-1/2 px-48" type="button" onClick={closeModal}>
                {formatMessage({ id: 'cancel', defaultMessage: 'Cancel' })}
              </Button>
            </div>
          </AddressForm>
        </div>
      </Modal>
    </>
  );
};

export default AddressModal;
