import React, { useEffect, useMemo, useState } from 'react';
import Link from 'components/commercetools-ui/atoms/link';
import Tooltip from 'components/commercetools-ui/atoms/tooltip';
import Typography from 'components/commercetools-ui/atoms/typography';
import AddressModal from 'components/commercetools-ui/organisms/checkout/components/create-address-modal';
import InfoIcon from 'components/icons/InfoIcon';
import { useFormat } from 'helpers/hooks/useFormat';
import { useAccount } from 'frontastic';
import InvalidAddressesCheckoutBox from './invalid-addresses-checkout-box';
import useMappers from '../../hooks/useMappers';
import { Address } from '../../types';

interface Props {
  className?: string;
  type: 'shipping' | 'billing';
  onSelectAddress: (address: Address) => void;
}

const AccountAddresses: React.FC<Props> = ({ className = '', type, onSelectAddress }) => {
  const { shippingAddresses, billingAddresses, defaultBillingAddress, defaultShippingAddress } = useAccount();
  const { formatMessage: formatCheckoutMessage } = useFormat({ name: 'error' });
  const { formatMessage } = useFormat({ name: 'checkout' });
  const { formatMessage: formatCommonMessage } = useFormat({ name: 'common' });
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });

  const { accountAddressToAddress } = useMappers();

  const addresses = useMemo(() => {
    const addresses = type === 'shipping' ? shippingAddresses : billingAddresses;

    return addresses.filter(
      (address) =>
        (address.isBillingAddress && address.phone && address.streetName && address.postalCode && address.city) ||
        !address.isBillingAddress,
    );
  }, [type, shippingAddresses, billingAddresses]);

  const [selected, setSelected] = useState<Address>();

  useEffect(() => {
    const billingAddress =
      defaultBillingAddress?.phone &&
      defaultBillingAddress?.streetName &&
      defaultBillingAddress?.postalCode &&
      defaultBillingAddress?.city
        ? defaultBillingAddress
        : null;
    const defaultAddress = type === 'shipping' ? defaultShippingAddress : billingAddress;

    if (defaultAddress) setSelected(accountAddressToAddress(defaultAddress));
  }, [defaultShippingAddress, defaultBillingAddress, accountAddressToAddress, type]);

  useEffect(() => {
    if (selected) onSelectAddress(selected);
  }, [selected, onSelectAddress]);

  const onClickCard = (address: Address) => {
    if (address.phone) {
      setSelected(address);
    }
  };

  const [show, setShow] = useState(false);

  const message = (
    <>
      <span>
        {formatAccountMessage({
          id: 'address.billing',
          defaultMessage: 'To edit your billing address, please contact our Customer Service Department',
        })}
        :{' '}
        <Link link={`/account/?hash=support`} target="_blank" className="font-bold text-base-accent-1">
          {formatCommonMessage({
            id: 'click.here',
            defaultMessage: 'click here',
          })}
        </Link>
      </span>
    </>
  );

  return (
    <>
      {/* Info box requesting user to create address */}
      {addresses.length === 0 && <InvalidAddressesCheckoutBox />}

      {/* Normal list of addresses when the billing address has phone number */}
      {addresses.length !== 0 && (
        <div className={`flex grid-cols-3 flex-col gap-12 md:grid ${className}`}>
          {addresses
            .map((address) => accountAddressToAddress(address))
            .map((address) => (
              <div
                key={address.addressId}
                className={`${
                  !address.phone
                    ? 'border-status-danger-border'
                    : address.addressId === selected?.addressId
                    ? 'hover:shadow-custom border-base-accent-1'
                    : 'hover:shadow-custom border-neutral-2'
                } relative flex flex-col gap-12 border px-12 py-20`}
              >
                {/* Header */}
                <div className="flex w-full justify-between">
                  {/* Address type label */}
                  <span className="font-bold">
                    {address.addressType !== 'billing'
                      ? formatMessage({ id: 'shippingAddress', defaultMessage: 'Shipping address' })
                      : formatMessage({ id: 'billingAddress', defaultMessage: 'Billing address' })}
                  </span>

                  {/* Card icons */}
                  <div className="flex gap-4">
                    {/* Info icon with tooltip */}
                    {address.addressType === 'billing' && (
                      <Tooltip
                        icon={<InfoIcon />}
                        message={message}
                        show={show}
                        setShow={(show) => setShow(show ?? false)}
                        tooltipWrapperMargin="max-md:-mb-12 mb-8"
                        additionalWrapperStyles="z-10"
                      />
                    )}

                    {/* Pencil icon to edit address */}
                    {address.addressType !== 'billing' && <AddressModal addressToUpdate={address} />}
                  </div>
                </div>

                {/* Address content */}
                <div
                  key={address.addressId}
                  className={`${!address.phone ? 'cursor-not-allowed' : 'cursor-pointer'} flex items-center`}
                  onClick={() => onClickCard(address)}
                >
                  <div className="flex max-w-full flex-col gap-4 overflow-hidden truncate text-16 leading-[20px] text-neutral-4">
                    <p className="font-bold">
                      {address.firstName} {address.lastName}
                    </p>
                    {address.company && <p>{address.company}</p>}
                    <p>
                      {address.line1} {address.number}
                    </p>
                    <p>
                      {address.postalCode} {address.city}
                    </p>
                    {address.phone && <p>{address.phone}</p>}
                  </div>
                </div>

                {/* Info message if address doesn't contain phone */}
                {!address.phone && (
                  <div className="flex items-center gap-x-4 text-status-danger-border">
                    <span className="h-24 w-24">
                      <InfoIcon />
                    </span>
                    <Typography className="text-14 leading-[17.5px]">
                      {formatCheckoutMessage({
                        id: 'address.update',
                        defaultMessage:
                          'This address is missing a phone number. Please add one via the Edit Button to use this address',
                      })}
                    </Typography>
                  </div>
                )}
              </div>
            ))}
        </div>
      )}
    </>
  );
};

export default AccountAddresses;
