import React, { useCallback, useEffect, useState } from 'react';
import { ShippingMethod } from 'shared/types/cart';
import Modal from 'components/commercetools-ui/atoms/modal';
import Typography from 'components/commercetools-ui/atoms/typography';
import CloseIcon from 'components/icons/CloseIcon';
import { DPD_KEY } from 'helpers/constants/dpd';
import { useFormat } from 'helpers/hooks/useFormat';

export interface DPDPickupLocationModalProps extends Pick<ShippingMethod, 'pickupType'> {
  setPickupPoint: React.Dispatch<React.SetStateAction<string | undefined>>;
  children: React.ReactNode;
}

const DPDPickupLocationModal = ({ children, pickupType, setPickupPoint }: DPDPickupLocationModalProps) => {
  const { formatMessage: formatCheckoutMessage } = useFormat({ name: 'checkout' });

  const [isOpen, setIsOpen] = useState(false);

  const openModal = useCallback(() => {
    setIsOpen(true);
  }, []);

  const closeModal = useCallback(() => setIsOpen(false), []);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const dpdWidgetEventListener = (event: any) => {
      if (event.data.point_id) {
        setPickupPoint(event.data.point_id);
      }
    };

    window.addEventListener('message', dpdWidgetEventListener);
    return () => window.removeEventListener('message', dpdWidgetEventListener);
  }, [setPickupPoint]);

  const map = `//pudofinder.dpd.com.pl/widget?key=${DPD_KEY}&hideFilters=1`;
  const mapOnlyStores = `${map}&points_with_services=1`;
  const pickupPointMap = pickupType === 'store' ? mapOnlyStores : map;

  return (
    <>
      <div onClick={openModal} className="h-full">
        {children}
      </div>
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        style={{ content: { background: 'transparent', border: 'none' } }}
        closeTimeoutMS={200}
        maxWidth={988}
      >
        <>
          <div
            className="invisible absolute right-12 top-12 hover:cursor-pointer lg:visible lg:right-24 lg:top-20"
            onClick={closeModal}
          >
            <CloseIcon />
          </div>
          <div className="shadow-custom mx-auto flex flex-col gap-20 bg-white px-12 py-20 lg:p-32">
            <div className="flex justify-between gap-12">
              <Typography className="text-24 font-bold leading-[30px] text-neutral-4">
                {formatCheckoutMessage({ id: 'selectPickupLocation', defaultMessage: 'Please select a DPD station' })}
              </Typography>
              <div className="hover:cursor-pointer lg:invisible" onClick={closeModal}>
                <CloseIcon />
              </div>
            </div>
            <iframe
              src={pickupPointMap}
              allow="geolocation *"
              className="min-h-[400px] w-full border-none lg:min-h-[580px]"
            ></iframe>
          </div>
        </>
      </Modal>
    </>
  );
};

export default DPDPickupLocationModal;
