import { ShippingMethod } from 'shared/types/cart';
import {
  SHIPPING_METHOD_DPD_PICKUP,
  SHIPPING_METHOD_STANDARD_PARCEL,
} from 'components/commercetools-ui/organisms/checkout/components/steps/constants/shippingMethodKeys';

export const getShippingMethodImage = (shippingMethodKey: ShippingMethod['key']) => {
  if (shippingMethodKey === SHIPPING_METHOD_DPD_PICKUP) return { src: '/images/DPD_Pickup.png', iconClassName: 'w-40' };
  if (shippingMethodKey?.startsWith(SHIPPING_METHOD_STANDARD_PARCEL))
    return { src: '/images/DPD.png', iconClassName: 'w-58' };

  return { src: '/images/delivery_methods_placeholder.svg', iconClassName: 'w-58' };
};
