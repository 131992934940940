import React, { useCallback, useMemo, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import * as yup from 'yup';
import Button from 'components/commercetools-ui/atoms/button';
import triggerToast from 'components/commercetools-ui/atoms/toaster/utils/triggerToast';
import { useFormat } from 'helpers/hooks/useFormat';
import useProcessing from 'helpers/hooks/useProcessing';
import { useAccount, useCart } from 'frontastic';
import { CartDetails } from 'frontastic/hooks/useCart/types';
import AccountAddresses from './components/account-addresses';
import useMappers from './hooks/useMappers';
import { Address } from './types';

export interface Props {
  goToNextStep: () => void;
}

const Addresses: React.FC<Props> = ({ goToNextStep }) => {
  const { formatMessage: formatCheckoutMessage } = useFormat({ name: 'checkout' });
  const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });

  const { account, isLoading, shippingAddresses, billingAddresses } = useAccount();

  const { updateCart } = useCart();

  const { addressToAccountAddress, accountAddressToAddress } = useMappers();

  const initialAddressData = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    line1: '',
    number: '',
    postalCode: '',
    city: '',
    company: '',
  } as Address;

  const [shippingAddress, setShippingAddress] = useState(initialAddressData);
  const billingAddress = billingAddresses.find((address) => address.isDefaultBillingAddress);
  const currentBillingAddress = useMemo(
    () => (billingAddress ? accountAddressToAddress(billingAddress) : shippingAddress),
    [accountAddressToAddress, billingAddress, shippingAddress],
  );

  const shippingAddressValidationSchema = useMemo(() => {
    return yup.object().shape({
      email: yup.string().email().required(),
      phone: yup.string().required(),
      line1: yup.string().trim().required(),
      postalCode: yup.string().trim().required(),
      city: yup.string().trim().required(),
    });
  }, []);

  const billingAddressValidationSchema = useMemo(() => {
    return yup.object().shape({
      email: yup.string().email().required(),
      phone: yup.string().optional(),
      line1: yup.string().trim().required(),
      postalCode: yup.string().trim().required(),
      city: yup.string().trim().required(),
    });
  }, []);

  const isValidShippingAddress = useMemo(() => {
    try {
      shippingAddressValidationSchema.validateSync(shippingAddress);
      return true;
    } catch (err) {
      return false;
    }
  }, [shippingAddressValidationSchema, shippingAddress]);

  const isValidBillingAddress = useMemo(() => {
    try {
      billingAddressValidationSchema.validateSync(currentBillingAddress);
      return true;
    } catch (err) {
      return false;
    }
  }, [billingAddressValidationSchema, currentBillingAddress]);

  const { processing, startProcessing, stopProcessing } = useProcessing();

  const submit = useCallback(async () => {
    if (!isValidShippingAddress || !isValidBillingAddress || processing) return;

    startProcessing();

    const data = {
      account: { email: account?.email || shippingAddress.email || currentBillingAddress.email },
      shipping: addressToAccountAddress(shippingAddress),
      billing: addressToAccountAddress(currentBillingAddress),
    } as CartDetails;

    const res = await updateCart(data);

    stopProcessing();

    if (res.cartId) {
      goToNextStep();
    } else {
      triggerToast({
        variant: 'error',
        message: formatCheckoutMessage({
          id: 'update.addresses.error',
          defaultMessage: "Couldn't update your addresses information, please try again later.",
        }),
      });
    }
  }, [
    account,
    isValidShippingAddress,
    isValidBillingAddress,
    shippingAddress,
    currentBillingAddress,
    addressToAccountAddress,
    updateCart,
    goToNextStep,
    formatCheckoutMessage,
    processing,
    startProcessing,
    stopProcessing,
  ]);

  // Use similar logic from AccountAddresses to get valid addresses
  const addresses = useMemo(() => {
    return shippingAddresses.filter(
      (address) =>
        (address.isBillingAddress && address.phone && address.streetName && address.postalCode && address.city) ||
        !address.isBillingAddress,
    );
  }, [shippingAddresses]);

  return (
    <div className="bg-white">
      {isLoading ? (
        <Skeleton className="h-[180px]" />
      ) : shippingAddresses.length > 0 ? (
        <div className="flex flex-col gap-20">
          <AccountAddresses type="shipping" onSelectAddress={(address) => setShippingAddress(address)} />

          {/* When there are no valid addresses, an info box will appear and we don't show this button */}
          {addresses.length !== 0 && (
            <Button
              variant="primary"
              className="w-full min-w-[200px] md:text-16 lg:w-fit lg:px-36"
              disabled={!isValidShippingAddress || !isValidBillingAddress}
              loading={processing}
              type="submit"
              onClick={submit}
            >
              {formatCartMessage({ id: 'continue.to', defaultMessage: 'Continue to' })}{' '}
              <span className="lowercase">{formatCartMessage({ id: 'payment', defaultMessage: 'Payment' })}</span>
            </Button>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default Addresses;
