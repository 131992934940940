import React from 'react';
import { useFormat } from 'helpers/hooks/useFormat';
import { useCart } from 'frontastic';
import Preview from '../wrapper';

interface Props {
  pickupPoint?: string | undefined;
}

const AddressesPreview: React.FC<Props> = ({ pickupPoint }) => {
  const { data } = useCart();

  const shippingAddress = data?.shippingAddress ?? null;
  const billingAddress = data?.billingAddress ?? null;

  const { formatMessage: formatCheckoutMessage } = useFormat({ name: 'checkout' });

  const shippingAddressPreview = shippingAddress ? (
    <div className="w-full">
      <p className="text-16 font-bold leading-[20px] text-neutral-4 lg:pt-24">
        {formatCheckoutMessage({ id: 'shippingAddress', defaultMessage: 'Shipping address' })}
      </p>
      <p className="pt-8 text-16 leading-[20px] text-neutral-4">
        <span className="font-bold">{`${shippingAddress.firstName ?? ''} ${shippingAddress.lastName ?? ''}`}</span>
        {shippingAddress.company ? (
          <>
            <span className="mt-4 block" />
            {shippingAddress.company}
          </>
        ) : null}
        <span className="mt-4 block" />
        {shippingAddress.streetName} {shippingAddress.streetNumber}
        <span className="mt-4 block" />
        {shippingAddress.postalCode} {shippingAddress.city}
      </p>
    </div>
  ) : null;

  const shippingAddressDPDPreview = shippingAddress ? (
    <div className="w-full">
      <p className="text-16 font-bold leading-[20px] text-neutral-4 lg:pt-24">
        {formatCheckoutMessage({ id: 'contactInformation', defaultMessage: 'Shipping address' })}
      </p>
      <p className="pt-8 text-16 leading-[20px] text-neutral-4">
        <span className="font-bold">{`${shippingAddress.firstName ?? ''} ${shippingAddress.lastName ?? ''}`}</span>
        <span className="mt-4 block" />
        {shippingAddress.phone}
      </p>
    </div>
  ) : null;

  return (
    <Preview>
      <div className="flex flex-col justify-between gap-24 border-neutral-2 lg:flex-row lg:gap-20 lg:border-t">
        {pickupPoint ? shippingAddressDPDPreview : shippingAddressPreview}
        {billingAddress ? (
          <div className="w-full">
            <p className="text-16 font-bold leading-[20px] text-neutral-4 lg:pt-24">
              {formatCheckoutMessage({ id: 'billingAddress', defaultMessage: 'Billing address' })}
            </p>
            <p className="pt-8 text-16 leading-[20px] text-neutral-4">
              <span className="font-bold">{`${billingAddress.firstName} ${billingAddress.lastName ?? ''}`}</span>
              {billingAddress.company ? (
                <>
                  <span className="mt-4 block" />
                  {billingAddress.company}
                </>
              ) : null}
              <span className="mt-4 block" />
              {billingAddress.streetName} {billingAddress.streetNumber}
              <span className="mt-4 block" />
              {billingAddress.postalCode} {billingAddress.city}
            </p>
          </div>
        ) : null}
      </div>
    </Preview>
  );
};

export default AddressesPreview;
